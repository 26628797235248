<template>
  <div>
    <label class="font-weight-bolder font-medium-4">{{ $t("Catalog Defaults") }}</label>
    <b-row>
      <b-col md="6">
        <div style="height: 50px; margin-bottom: 1rem">
          <span
            class="font-weight-bolder d-inline-block"
            style="margin-bottom: 2px"
          >{{ "Status" }}</span>
          <div class="d-flex align-items-center justify-content-between">
            <span class="ml-1">{{
              catalogSettingsFields["catalog_defaults_is_active"].label
            }}</span>
            <b-skeleton
              width="20px"
              height="20px"
              style="border-radius: 5px; margin: 0"
            />
          </div>
        </div>
        <div style="height: 70px">
          <span
            class="font-weight-bolder"
            style="padding-left: 8px"
          >{{
            catalogSettingsFields["catalog_defaults_default_type_id"].label
          }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
        <span
          class="font-weight-bolder d-inline-block"
          style="padding-left: 8px; margin-bottom: 8px"
        >{{ $t("Default Fulfillment Rules") }}</span>
        <div
          v-for="field in [
            'catalog_defaults_by_default_requires_a_container',
            'catalog_defaults_by_default_is_serialized',
          ]"
          :key="field"
          class="d-flex align-items-center"
          style="margin-bottom: 10px"
        >
          <b-skeleton
            width="20px"
            height="20px"
            style="border-radius: 5px; margin: 0"
          />
          <span class="ml-1">{{ catalogSettingsFields[field].label }}</span>
        </div>
      </b-col>
      <b-col md="6">
        <div style="height: 70px">
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px"
          >{{
            catalogSettingsFields["catalog_defaults_default_allow_discount"].label
          }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>

        <span
          class="font-weight-bolder d-block"
          style="margin-bottom: 6px"
        >{{ $t("Default Assets’ Rules") }}
        </span>
        <div
          v-for="field in [
            'catalog_defaults_default_assets_rules_needs_individual_asset_id',
            'catalog_defaults_default_assets_rules_requires_individual_asset_price',
            'catalog_defaults_default_assets_rules_can_be_procured',
            'catalog_defaults_default_assets_rules_can_be_rented',
            'catalog_defaults_default_assets_rules_can_be_sub_rented',
            'catalog_defaults_default_assets_rules_can_be_sold',
          ]"
          :key="field"
          class="d-flex align-items-center"
          style="margin-bottom: 10px"
        >
          <b-skeleton
            width="20px"
            height="20px"
            style="border-radius: 5px; margin: 0"
          />
          <span class="ml-1">{{ catalogSettingsFields[field].label }}</span>
        </div>
        <div style="height: 70px">
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px"
          >{{
            catalogSettingsFields["catalog_defaults_default_turnaround_time"]
              .label
          }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <label
      style="font-size: 20px"
      class="inputLabel"
    >{{
      $t("Default Preparation times")
    }}</label>
    <b-row>
      <b-col
        v-for="field in [
          'catalog_defaults_default_pick_time_per_item',
          'catalog_defaults_default_receive_time_per_item',
          'catalog_defaults_default_pack_time_per_item',
          'catalog_defaults_default_put_back_time_per_item',
        ]"
        :key="field"
        md="6"
      >
        <div style="height: 70px">
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px"
          >{{
            catalogSettingsFields[field].label
          }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BSkeleton, BCol } from 'bootstrap-vue'
import config from '../defaultsConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const { catalogSettingsFields } = config()
    return {
      catalogSettingsFields,
    }
  },
}
</script>
