var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"font-weight-bolder font-medium-4"},[_vm._v(_vm._s(_vm.$t("Catalog Defaults")))]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticStyle:{"height":"50px","margin-bottom":"1rem"}},[_c('span',{staticClass:"font-weight-bolder d-inline-block",staticStyle:{"margin-bottom":"2px"}},[_vm._v(_vm._s("Status"))]),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.catalogSettingsFields["catalog_defaults_is_active"].label))]),_c('b-skeleton',{staticStyle:{"border-radius":"5px","margin":"0"},attrs:{"width":"20px","height":"20px"}})],1)]),_c('div',{staticStyle:{"height":"70px"}},[_c('span',{staticClass:"font-weight-bolder",staticStyle:{"padding-left":"8px"}},[_vm._v(_vm._s(_vm.catalogSettingsFields["catalog_defaults_default_type_id"].label))]),_c('b-skeleton',{staticStyle:{"border-radius":"5px"},attrs:{"height":"40px"}})],1),_c('span',{staticClass:"font-weight-bolder d-inline-block",staticStyle:{"padding-left":"8px","margin-bottom":"8px"}},[_vm._v(_vm._s(_vm.$t("Default Fulfillment Rules")))]),_vm._l(([
          'catalog_defaults_by_default_requires_a_container',
          'catalog_defaults_by_default_is_serialized' ]),function(field){return _c('div',{key:field,staticClass:"d-flex align-items-center",staticStyle:{"margin-bottom":"10px"}},[_c('b-skeleton',{staticStyle:{"border-radius":"5px","margin":"0"},attrs:{"width":"20px","height":"20px"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.catalogSettingsFields[field].label))])],1)})],2),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticStyle:{"height":"70px"}},[_c('span',{staticClass:"font-weight-bolder d-block",staticStyle:{"margin-bottom":"5px"}},[_vm._v(_vm._s(_vm.catalogSettingsFields["catalog_defaults_default_allow_discount"].label))]),_c('b-skeleton',{staticStyle:{"border-radius":"5px"},attrs:{"height":"40px"}})],1),_c('span',{staticClass:"font-weight-bolder d-block",staticStyle:{"margin-bottom":"6px"}},[_vm._v(_vm._s(_vm.$t("Default Assets’ Rules"))+" ")]),_vm._l(([
          'catalog_defaults_default_assets_rules_needs_individual_asset_id',
          'catalog_defaults_default_assets_rules_requires_individual_asset_price',
          'catalog_defaults_default_assets_rules_can_be_procured',
          'catalog_defaults_default_assets_rules_can_be_rented',
          'catalog_defaults_default_assets_rules_can_be_sub_rented',
          'catalog_defaults_default_assets_rules_can_be_sold' ]),function(field){return _c('div',{key:field,staticClass:"d-flex align-items-center",staticStyle:{"margin-bottom":"10px"}},[_c('b-skeleton',{staticStyle:{"border-radius":"5px","margin":"0"},attrs:{"width":"20px","height":"20px"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.catalogSettingsFields[field].label))])],1)}),_c('div',{staticStyle:{"height":"70px"}},[_c('span',{staticClass:"font-weight-bolder d-block",staticStyle:{"margin-bottom":"5px"}},[_vm._v(_vm._s(_vm.catalogSettingsFields["catalog_defaults_default_turnaround_time"] .label))]),_c('b-skeleton',{staticStyle:{"border-radius":"5px"},attrs:{"height":"40px"}})],1)],2)],1),_c('label',{staticClass:"inputLabel",staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.$t("Default Preparation times")))]),_c('b-row',_vm._l(([
        'catalog_defaults_default_pick_time_per_item',
        'catalog_defaults_default_receive_time_per_item',
        'catalog_defaults_default_pack_time_per_item',
        'catalog_defaults_default_put_back_time_per_item' ]),function(field){return _c('b-col',{key:field,attrs:{"md":"6"}},[_c('div',{staticStyle:{"height":"70px"}},[_c('span',{staticClass:"font-weight-bolder d-block",staticStyle:{"margin-bottom":"5px"}},[_vm._v(_vm._s(_vm.catalogSettingsFields[field].label))]),_c('b-skeleton',{staticStyle:{"border-radius":"5px"},attrs:{"height":"40px"}})],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }