<template>
  <div>
    <skeletons v-if="loading" />
    <default-form
      v-else
      ref="default-form"
      @refetch="refetch"
    />
  </div>
</template>

<script>
import store from '@/store'
import catalogModule from '@/store/settings/catalog/defaults'
import { onUnmounted } from '@vue/composition-api'
import DefaultForm from './components/DefaultsForm.vue'
import config from './defaultsConfig'
import Skeletons from './components/Skeletons.vue'

export default {
  name: 'Defaults',
  components: { Skeletons, DefaultForm },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    this.$emit('updateMenu', 'settings-catalog-defaults')
    this.refetch()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['default-form']) this.$refs['default-form'].showConfirmationModal(next)
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getDefaultSettings`).then(res => {
        const { data } = res
        const mappedObj = {}

        data.forEach(item => {
          if (item.key !== undefined) {
            mappedObj[item.key] = JSON.parse(item.value) // ==> parsing string true/ false to boolean
          }
        })
        // eslint-disable-next-line no-prototype-builtins
        const defaultType = data.find(item => item.hasOwnProperty('default_type'))?.default_type
        const defaultSettings = this.mappingFields(Object.keys(this.catalogSettingsFields), mappedObj, {
          catalog_defaults_default_type_id: defaultType,
        })

        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, defaultSettings)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_CATALOG_DEFAULT_FORM_CLONE`, defaultSettings)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const { catalogSettingsFields } = config()
    const MODULE_NAME = 'settings-catalog-defaults'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, catalogModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      catalogSettingsFields,
    }
  },
}
</script>
