import {
  CHECKBOX_LEF,
  L_ACTIVE_CHECK_LF_BOX,
  SELECT_INPUT,
  TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const catalogSettingsFields = {
    catalog_defaults_is_active: {
      type: L_ACTIVE_CHECK_LF_BOX,
      label: 'Active',
      rules: 'required',
    },
    catalog_defaults_default_type_id: {
      type: SELECT_INPUT,
      label: 'Default Type',
      store: 'productTypesList',
      rules: 'required',
      options: {
        label: 'name',
      },
    },
    catalog_defaults_default_allow_discount: {
      type: TEXT_INPUT,
      label: 'Allow discount up to',
      rules: 'required|numeric|max_value:100',
      placeholder: 'Type...',
    },
    catalog_defaults_default_pick_time_per_item: {
      type: TEXT_INPUT,
      label: 'Pick time per item (in minutes)',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    catalog_defaults_default_pack_time_per_item: {
      type: TEXT_INPUT,
      label: 'Pack time per item (in minutes)',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },

    catalog_defaults_default_receive_time_per_item: {
      type: TEXT_INPUT,
      label: 'Receive time per item (in minutes)',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    catalog_defaults_default_put_back_time_per_item: {
      type: TEXT_INPUT,
      label: 'Put back time per item (in minutes)',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    catalog_defaults_default_turnaround_time: {
      type: TEXT_INPUT,
      label: 'Default Turnaround time (in minutes)',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    catalog_defaults_default_product_rules_can_be_transferred: {
      type: CHECKBOX_LEF,
      label: 'Can be transferred',
    },
    catalog_defaults_by_default_requires_a_container: {
      type: CHECKBOX_LEF,
      label: 'By default, requires a container',
    },
    catalog_defaults_by_default_is_serialized: {
      type: CHECKBOX_LEF,
      label: 'By default, Is serialized',
    },

    // Assets Rules
    catalog_defaults_default_assets_rules_needs_individual_asset_id: {
      type: CHECKBOX_LEF,
      label: 'Needs Individual asset ID',
    },
    catalog_defaults_default_assets_rules_requires_individual_asset_price: {
      type: CHECKBOX_LEF,
      label: 'Requires Individual asset price',
    },
    catalog_defaults_default_assets_rules_can_be_procured: {
      type: CHECKBOX_LEF,
      label: 'Can be procured',
    },
    catalog_defaults_default_assets_rules_can_be_rented: {
      type: CHECKBOX_LEF,
      label: 'Can be rented',
    },
    catalog_defaults_default_assets_rules_can_be_sub_rented: {
      type: CHECKBOX_LEF,
      label: 'Can be sub-rented',
    },
    catalog_defaults_default_assets_rules_can_be_sold: {
      type: CHECKBOX_LEF,
      label: 'Can be sold',
    },

    //  Default Fulfillment Rules
    catalog_defaults_default_fulfillment_rules_can_be_transferred: {
      type: CHECKBOX_LEF,
      label: 'Can be transferred',
    },

    catalog_defaults_default_fulfillment_rules_requires_a_container: {
      type: CHECKBOX_LEF,
      label: 'Requires a container',
    },
  }
  return {
    catalogSettingsFields,
  }
}
